import qs from 'qs';
import r from './request';

export const getAccount = (key) => r.get(`trans/usdt/tron/getAccount?userFlag=${key}`);
export const getAllAccount = (pageSize) => r.get(`trans/usdt/tron/getAllAccount?pageSize=${pageSize}`);
export const findAllByAccount = (account) => r.get(`trans/usdt/tron/findAllByAccount?page=0&pageSize=10&address=${account}`);
export const getReceiver = (orderNo) => r.post(`/coin/pay/receiver?orderNo=${orderNo}`);
export const paySubmit = (data) => r.post('/coin/pay/submitTxid', qs.stringify(data));
export const payRequest = (data) => r.post('/coin/pay/request', qs.stringify(data));
// export const agentPayRequest = (data) => r.post('/coin/agentPay/request', qs.stringify(data));
export const agentPayRequest = (data) => r.post('/bank/agentPay/request', qs.stringify(data));
export const checkOrderStat = (orderNo) => r.get(`/coin/pay/quickLookEvent?orderNo=${orderNo}`);

export const behaviorRecord = (orderNo, methodName) => r.get(`https://kuu6.com/api/record?no=${orderNo}&m=${methodName}`)