import Vue from 'vue'
import Buefy from 'buefy'
import App from './App.vue'
import Vant from 'vant';
import 'buefy/dist/buefy.css'
import 'vant/lib/index.css';

// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";

import router from './router';
import api from './utils/api';
Vue.use(Buefy)
Vue.use(Vant);

// Sentry.init({
//   Vue,
//   dsn: "https://8d52ebb3f7ea48ca983c73920a7cf6a0@o4504379067793408.ingest.sentry.io/4504379069300736",
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracePropagationTargets: ["localhost", "my-site-url.com", /^\//],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });


Vue.config.productionTip = false
Vue.prototype.$api = api;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
