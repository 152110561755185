import axios from 'axios';

const r = axios.create({
  baseURL: '/api',
  // baseURL: 'https://66.42.38.140:18787/api',
  timeout: 1000 * 10,
});

r.interceptors.request.use((config) => {
    return config;
  },(err) => {
    return Promise.reject(err);
  },
);

r.interceptors.response.use((response) => {
    return response.data;
  }, (err) => {
    if (302 === err.response.status) {
      // window.location.href=""
    } else {
      return Promise.reject(err);
    }
    
  },
);

export default r;
