import {
  getAccount,
  getAllAccount,
  findAllByAccount,
  getReceiver,
  paySubmit,
  payRequest,
  agentPayRequest,
  checkOrderStat,
  behaviorRecord,
} from './func';

export default {
  getAccount,
  getAllAccount,
  findAllByAccount,
  getReceiver,
  paySubmit,
  payRequest,
  agentPayRequest,
  checkOrderStat,
  behaviorRecord
};
