<template>
  <div id="pay" class="container">
    <div class="logo-box"><img src="../assets/images/upi.jpg" alt=""></div>
    <!-- <div class="info" style="text-align: center;">
      <strong>DEMO VIDEO >></strong>
    </div> -->
    <!-- <div class="center-section">
      <div class="center-section-item">
        <div class="header em">Step 1.Copy UPI Information</div>
        <div class="cell">
          <div><span style="font-weight: bold;">Amount:</span> <span class="em">{{amount}}</span></div>
          <div class="copybtn amountForCopy" style="text-align: center;" :data-clipboard-text="amount" @click="copyAmount">copy</div>
        </div>
        <div class="cell bottom-border">
          <span style="font-weight: bold;">VPA/UPI:</span> <span>{{upi}}</span>
          <div class="copybtn upiForCopy" style="text-align: center;" :data-clipboard-text="upi" @click="copyUPI">copy</div>
        </div>
      </div>
    </div> -->
    <div class="center-section">
      <div class="center-section-item">
        <div class="header em">Step 1.Please choose your preferable payment APP and click to pay.</div>
        <div class="cell">
          <van-icon name="info-o" color="#ee0a24" />
          <span class="tips">Please record your reference No.{Ref No.} after payment</span>
        </div>
        <!-- <div class="cell bottom-border" style="display: flex;flex-direction: column;">
          <div  style="display: flex;flex-direction: column;justify-content: space-between;">
            <div style="font-weight: bold;">Option1:</div>
            <div>Scan QR with Paytm or Google Pay or any UPI APP</div>
            <div style="color: #868686;">Pay using Paytm Wallet or Google Pay or other UPI Apps</div>
          </div>
          <div style="width: 4rem;height: 4rem;margin-bottom: .2rem;" class="qrcode" ref="qrCodeUrl"></div>
        </div> -->
        <div class="cell bottom-border">
          <!-- <div style="font-weight: bold;">Option2:</div> -->
          <div v-if="amountLimit" style="display: flex;justify-content: center;width: 100%;">
            <span style="color: red;">We only accept amount of 100 RS right now.</span>
          </div>
          <!-- <div v-if="!amountLimit" style="display: flex;justify-content: center;width: 100%;" @click="touchBtn('other')">
            <van-button class="button-29" :icon="require('../assets/images/gpay.png')" :url="`tez://upi/pay?mc=5732&pa=${this.upi}&pn=awesomePay&am=${this.amount}&cu=INR&tn=${this.orderNo}&tr=${this.orderNo}`" type="info">
              GPay
            </van-button>
          </div> -->
          <van-grid direction="horizontal" :column-num="2" v-if="!amountLimit">
            <!-- <van-grid-item @click="touchBtn('paytm')">
              <van-button class="button-29" url="paytmmp://pay?pa=9810344156@axisbank&pn=Ritimon%20Nongrum&tid=R1677826257595675&tn=899A6YRPUD2N&am=100.00&cu=INR" type="info">
                paytm://9810344156(100)
              </van-button>
            </van-grid-item> -->
            <!-- <van-grid-item @click="touchBtn('paytm')">
              <van-button class="button-29" url="paytmmp://pay?pa=8146639746@paytm&pn=Ritimon%20Nongrum&tid=R1677826257595675&tn=899A6YRPUD2N&am=100.00&cu=INR" type="info">
                paytm://8146639746(100)
              </van-button>
            </van-grid-item> -->
            <van-grid-item @click="touchBtn('paytm')">
              <van-button class="button-29" :url="`paytmmp://pay?mc=5732&pa=${upiID}&pn=${pnID}&tid=P${paytime}&tn=${paytime.slice(-6)}&am=10.00&cu=INR`" type="info">
                paytm://{{upiID}}
              </van-button>
            </van-grid-item>
            <van-grid-item @click="touchBtn('phonepe')">
              <van-button class="button-29" :url="`phonepe://pay?mc=5732&pa=${upiID}&pn=${pnID}&am=10.0&tid=PE${paytime}&cu=INR&tn=${paytime.slice(-6)}&mode=04`" type="info">
                phonepe://{{upiID}}
              </van-button>
            </van-grid-item>
            <van-grid-item @click="touchBtn('upi')">
              <van-button class="button-29" :url="`upi://pay?mc=5732&pa=${upiID}&pn=${pnID}&tid=U${paytime}&am=10.0&cu=INR&tn=${paytime.slice(-6)}`" type="info">
                upi://{{upiID}}
              </van-button>
            </van-grid-item>
            <van-grid-item @click="touchBtn('tez')">
              <van-button class="button-29" :url="`tez://upi/pay?mc=5732&pa=${upiID}&pn=${pnID}&tid=T${paytime}&am=10.0&cu=INR&tn=${paytime.slice(-6)}`" type="info">
                tez://{{upiID}}
              </van-button>
            </van-grid-item>
            <van-grid-item @click="touchBtn('gpay')">
              <van-button class="button-29" :url="`gpay://upi/pay?mc=5732&pa=${upiID}&pn=${pnID}&tid=G${paytime}&am=10.0&cu=INR&tn=${paytime.slice(-6)}`" type="info">
                gpay://{{upiID}}
              </van-button>
            </van-grid-item>
            <!-- <van-grid-item @click="touchBtn('gpay')">
              <van-button class="button-29" :icon="require('../assets/images/gpay.png')" url="upi://pay?mc=5732&pa=8146639746@paytm&pn=awesomePay&am=100.0&cu=INR&tn=fromUpi" type="info">
                upi://lucky
              </van-button>
            </van-grid-item>
            <van-grid-item @click="touchBtn('other')">
              <van-button class="button-29" :icon="require('../assets/images/bhim.png')" url="tez://upi/pay?mc=5732&pa=8146639746@paytm&pn=awesomePay&am=100.0&cu=INR&tn=fromGpay&mode=04" type="info">
                gpay://lucky
              </van-button>
            </van-grid-item> -->
          </van-grid>
          <!-- <van-grid direction="horizontal" :column-num="2" v-if="!amountLimit">
            <van-grid-item @click="touchBtn('paytm')">
              <van-button class="button-29" :icon="require('../assets/images/paytm.png')" :url="`paytmmp://pay?pa=${upi}&am=${amount}&cu=INR`" type="info">
                Paytm
              </van-button>
            </van-grid-item>
            <van-grid-item @click="touchBtn('phonepe')">
              <van-button class="button-29" :icon="require('../assets/images/PhonePe.png')" :url="`phonepe://`" type="info">
                PhonePe
              </van-button>
            </van-grid-item>
            <van-grid-item @click="touchBtn('gpay')">
              <van-button class="button-29" :icon="require('../assets/images/gpay.png')" :url="`gpay://upi/pay?mc=5732&pa=${upi}&pn=awesomePay&am=${amount}&cu=INR&tn=${orderNo}&tr=${orderNo}&url=${refUrl}`" type="info">
                GPay
              </van-button>
            </van-grid-item>
            <van-grid-item @click="touchBtn('other')">
              <van-button class="button-29" :icon="require('../assets/images/bhim.png')" :url="`upi://pay?pa=${this.upi}&pn=HiPay-H7UR4PNY&am=${this.amount}&cu=INR&tr=${this.orderNo}`" type="info">
                Other
              </van-button>
            </van-grid-item>
          </van-grid> -->
        </div>
      </div>
    </div>
    <div class="center-section">
      <div class="center-section-item">
        <div class="header em">Step 2.Please enter Ref No. to complete the recharge</div>
        <div class="cell">
          <van-icon name="info-o" color="#ee0a24" />
          <span class="tips">Be sure to <span style="color: red;">return</span> this page to fill in the <span style="color: red;">UTR numbers after</span> you have completed your payment</span>
        </div>
        <div class="cell bottom-border input-section">
          <input :disabled="isSubmit" class="utr-input" type="text" onkeyup="value=value.replace(/[^\d]/g,'')" maxlength="12" v-model="formData.txid" placeholder="UTR (UPI Ref.ID) must be 12 digits" />
        </div>
      </div>
    </div>
    <div class="info" @click="openTips()">
      <strong>TIPS:</strong> <span>Click here to know "UTR/REF No." >></span>
    </div>
    <div class="info" @click="watchVideo()">
      <strong>DEMO VIDEO >></strong>
    </div>
    <div class="btn-area">
      <van-button class="button" size="large" @click="submitPay" :disabled="isSubmit">Submit UTR</van-button>
    </div>
    <div class="center-section">
      <div class="center-section-item">
        <div class="tips" style="text-align: center;">©2022 awesomePay Technical Support All Rights Reserved</div>
        <div class="tips" style="text-align: center;">In case of any issuers or queries please contact <strong>service@bit345.com</strong> or try again</div>
      </div>
    </div>

    <div class="supports">
      <ul>
        <img src="../assets/images/upi_r.png" alt="">
        <img src="../assets/images/icici.jpg" alt="">
      </ul>
    </div>

    <van-popup class="modal-box" v-model="showModal">
      <div class="modal-tips" style="margin-bottom: .2rem;">
        <span style="font-weight: bold;">1. Please <em>do not save the current UPI account</em> for next use! Be sure to confirm that the UPI account you have obtained is the latest account.</span>
      </div>
      <div class="modal-tips" style="margin-bottom: .2rem;">
        <span style="font-weight: bold;">2. If you fail to comply with this agreement and cause financial losses that <em>cannot be recovered</em>, the platform will not be responsible.</span>
      </div>
      <div class="modal-tips" style="margin-bottom: .2rem;">
        *After you paid, you <em>must</em> come back here to <em>submit</em> the Ref/UTR No.(<em>12 digits</em>) . then your money is credited to your account.
      </div>
      <div class="info" style="margin-top: .2rem;" @click="watchVideo();">
        <span>DEMO VIDEO >></span>
      </div>
      <div class="scroll">
        <img src="../assets/images/sample.png" alt="">
      </div>
      
      <div class="modal-btn" @click="closeTips()">Confirm</div>
    </van-popup>

    <van-popup class="modal-box" v-model="videoModal">
      <div class="video">
        <video style="max-height: 600px;" src="../assets/images/video.mp4" controls autoplay></video>
      </div>
    </van-popup>
  </div>
</template>
<script>
import Clipboard from 'clipboard';
import QRCode from 'qrcodejs2';
export default {
  data() {
    return {
      amountLimit: false,
      amount: 100,
      upi: '',
      formData: {
        coinUnit:'INR',
        orderNo: '',
        txid: ''
      },
      showModal: false,
      videoModal: false,
      activeTab: 0,
      isLoading: false,
      isPaid: false,
      isSubmit: false,
      subFix: 0,
      orderNo: '',
      refUrl: '',
      receiveAddr: '',
      addrIndex: 0,
      startTime: null,
      paytime: '',
      upiID: '9878653504@kotak',
      pnID: 'YG010',
      // upiID: '8146639746@paytm',
    }
  },
  mounted() {
    this.paytime = parseInt(new Date().getTime() / 1000) + '';

    // const { orderNo } = this.$route.query;
    // if (orderNo) {
    //   this.orderNo = orderNo;
    //   this.formData.orderNo = orderNo;
    //   if (orderNo.length > 5) {
    //     this.getAccount(this.orderNo);
    //   } else {
    //     this.$notify({ type: 'danger', message: 'Invalid order No.' });
    //   }
    // } else {
    //   this.$notify({ type: 'danger', message: 'order No missing.' });
    // }
    // const { refUrl } = this.$route.query;
    // if (refUrl) {
    //   this.refUrl = refUrl;
    // }
    // this.startTime = new Date();
    // this.checker = setInterval(() => {
    //   this.checkStat();
    // }, 2000);
  },
  methods: {
    openUrl(url) {
      window.open(url, "_blank")
    },
    creatQrCode() {
      const dom = document.querySelector('.qrcode');
      const donChildObj = dom.childNodes;
      for (let i = donChildObj.length - 1; i >= 0; i -= 1) {
        dom.removeChild(donChildObj[i]);
      }
      // eslint-disable-next-line no-unused-vars
      const qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.qrText,
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    touchBtn(e) {
      this.$api.behaviorRecord(this.orderNo, `touch btn ${e}`);
    },
    openTips() {
      this.showModal=true;
      this.$api.behaviorRecord(this.orderNo, 'open tips');
    },
    closeTips() {
      this.showModal=false;
      this.$api.behaviorRecord(this.orderNo, 'close tips');
    },
    watchVideo() {
      this.videoModal=true;
      this.showModal=false;
      this.$api.behaviorRecord(this.orderNo, 'watch video');
    },
    howto() {
      this.$buefy.dialog.alert({
        title: 'How to find Ref No.',
        message: '<img src="../assets/images/example.jpeg" alt="example">',
        confirmText: 'OK'
      });
    },
    copyOrder() {
      const clipboard = new Clipboard('.orderForCopy');
      clipboard.on('success', () => {
        this.$api.behaviorRecord(this.orderNo, 'copy order');
        this.$notify({ type: 'success', message: 'Copied to clipboard.' });
      });
      clipboard.on('error', (e) => {
        console.log('error', e);
      });
    },
    copyAmount() {
      const clipboard = new Clipboard('.amountForCopy');
      clipboard.on('success', () => {
        this.$api.behaviorRecord(this.orderNo, 'copy amount');
        this.$notify({ type: 'success', message: 'Copied to clipboard.' });
      });
      clipboard.on('error', (e) => {
        console.log('error', e);
      });
    },
    copyUPI() {
      const clipboard = new Clipboard('.upiForCopy');
      clipboard.on('success', () => {
        this.$api.behaviorRecord(this.orderNo, 'copy upi');
        this.$notify({ type: 'success', message: 'Copied to clipboard.' });
      });
      clipboard.on('error', (e) => {
        console.log('error', e);
      });
    },
    async getAccount(key) {
      this.isLoading = true;
      const getAccountRes = await this.$api.getReceiver(key);
      console.log('getAccount', getAccountRes);
      if (getAccountRes.code === 200) {
        this.amount = getAccountRes.data.requestAmount;
        this.amount = parseInt(this.amount).toFixed(1);
        if (this.amount > 100) {
          this.amountLimit = true;
          this.isSubmit = true;
          this.$dialog.alert({
            message: 'We only accept amount of 100 RS right now.',
          });
          return;
        }
        this.upi = getAccountRes.data.receiveAddr;
        // upi://pay?mc=5732&pa=decode85.08@cmsidfc&pn=HiPay-H7UR4PNY&am=100.0&cu=INR&tn=H7UR4PNY
        this.qrText = `upi://pay?mc=5732&pa=${this.upi}&pn=awesomePay&am=${this.amount}&cu=INR&tn=${this.orderNo}&tr=${this.orderNo}`
        // this.receiveAddr = getAccountRes.data.receiveAddr;
        // this.amount = getAccountRes.data.requestAmount;
        // this.qrText = getAccountRes.data.receiveAddr;
        // this.signature = getAccountRes.data.sign;
        // this.trustDeeplink = `trust://send?asset=c195_tTR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t&address=${this.receiveAddr}&amount=${this.amount}&memo=${this.signature}`;
        // this.deepLink = `trust://send?asset=c195_tTR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t&address=${this.receiveAddr}`;
        // this.deepLinkMetamask = `https://metamask.app.link/send/0xdac17f958d2ee523a2206206994597c13d831ec7@56/transfer?address=${this.receiveAddr}&uint256=5e6`;
      }
      this.isLoading = false;
      this.creatQrCode();
    },
    async submitPay() {
      // this.$api.behaviorRecord(this.orderNo, 'submit utr start');
      if ( !this.formData.txid) {
        this.$notify({ type: 'danger', message: 'Need Ref No.' });
        return;
      }
      if (this.formData.txid.length!==12) {
        this.$dialog.alert({
          title: 'Wrong UTR',
          message: 'UTR must be a 12-digit number. Click "OK" to learn how to find it.',
          theme: 'round-button',
        }).then(() => {
          this.showModal = true;
        });
        return;
      }
      this.isSubmit = true;
      const result = await this.$api.paySubmit(this.formData);
      
      console.log(result);
      if (result.success) {
        this.$notify({ type: 'success', message: 'Submitted, wait patiently for confirmation.' });
        // this.$api.behaviorRecord(this.orderNo, 'submit utr success');
        if (result.data.hrefbackurl.length>0) {
          console.log('hrefbackurl found.');
          // setTimeout(() => {
          //   window.open(result.data.hrefbackurl)
          // }, 3000);
        } else {
          console.log('no hrefbackurl');
        }
      } else {
        this.$notify({ type: 'danger', message: result.message });
        // this.$api.behaviorRecord(this.orderNo, 'submit utr fail');
      }
    }
  },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  max-width: 800px;
  margin: 0 auto;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box
}


a {
  text-decoration: none;
  font-size: .3rem
}

button {
  outline: none;
  border: none
}

img {
  display: block
}

html {
  font-size: 13.333333vw
}

@media (min-width:560px) {
  html {
    font-size: 54px
  }
}

.logo-box {
  display: flex;
  justify-content: center;
  padding: .5rem 0;
  margin-top: .1rem;
  padding-bottom: .1rem;
  padding-top: .2rem
}

.center-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: .28rem
}

.center-section-item {
  width: 92%
}

.center-section-item .header {
  font-size: .28rem;
  color: #fff;
  background-color: #1bb0f3;
  padding-top: .2rem;
  padding-bottom: .2rem;
  min-height: .68rem;
  line-height: .32rem
}

.center-section-item .cell,
.center-section-item .header {
  border-right: 1px solid #c1c1c1;
  border-left: 1px solid #c1c1c1;
  padding-left: .2rem;
  display: flex;
  align-items: center
}

.center-section-item .cell {
  font-size: .26rem;
  min-height: .8rem;
  padding-right: .2rem
}

.center-section-item .cell span {
  padding-left: .1rem;
  word-break: break-all
}

.bottom-border {
  border-bottom: 1px solid #c1c1c1
}

.center-section-item .tips {
  line-height: .26rem;
  color: #868686;
  font-size: .2rem;
  padding: .2rem 0;
  padding-left: .2rem
}

.copybtn {
  min-width: 1.02rem;
  height: .54rem;
  line-height: .54rem;
  border-radius: .04rem;
  background: #00bcf8;
  font-size: .24rem;
  color: #fff;
  margin-left: auto
}

.em {
  color: red;
  font-weight: 700
}

em {
  color: red;
  font-style: normal
}

.utr-input {
  background-color: #f4f4f4;
  border-radius: 3px;
  height: .65rem;
  line-height: .65rem;
  font-size: .26rem;
  width: 90%;
  outline: none;
  border: 2px solid #1bb0f3;
  padding: 0 .2rem;
  display: block;
  margin: 0 auto;
  color: #1bb0f3
}

.input-section {
  padding-bottom: .2rem
}

.info {
  display: flex;
  padding: 0 .26rem
}

.info strong {
  color: #2b2b2b;
  font-size: .2rem;
  opacity: .8
}

.info span {
  color: #e81818;
  font-size: .2rem;
  opacity: .8
}

.btn-area {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px
}

.button {
  width: 3rem;
  height: .88rem;
  background: #00b8f2;
  border-radius: .16rem;
  font-size: .36rem;
  color: #fff;
  outline: none;
  border: none
}
.button[disabled] {
  color: #000000;
  border: none;
  background-color: #ededed;
  cursor: not-allowed;
}

.supports ul {
  display: flex;
  justify-content: center
}

.supports ul img {
  width: 1.8rem
}

.copys {
  width: 100%;
  z-index: 100;
  font-size: .23rem;
  color: #755757;
  text-align: center;
  bottom: .6rem;
  padding-bottom: .2rem;
  margin-top: .2rem
}

.modal-box {
  padding-top: .4rem;
  width: 6.5rem;
}

.modal-box .video {
  display: flex;
  justify-content: center;
}

.modal-box .modal-tips {
  font-size: .28rem;
  padding: 0 .25rem;
  color: #000
}

.modal-box .tips2 {
  font-size: .3rem
}

.modal-box .scroll {
  height: 300px;
  width: 100%;
  overflow: auto
}

.scroll img {
  width: 100%;
  height: 868px
}

.modal-box .modal-btn {
  width: 4rem;
  height: .6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .3rem;
  background: #19aff9;
  color: #fff;
  margin: .2rem auto .2rem;
  font-size: .3rem
}

.cutdown {
  display: block;
  text-align: center;
  font-size: .36rem;
  line-height: .6rem;
  font-weight: 700
}

.shadowBtn {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}


/* CSS */
.button-29 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
  border: 0;
  border-radius: .15rem;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  height: .8rem;
  /* width: 135px; */
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  /* padding-left: 16px;
  padding-right: 16px; */
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: .2rem;
  margin: 0.2rem 0;
}

.button-29:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-29:active {
  box-shadow: #3c4fe0 0 3px 7px inset;
  transform: translateY(2px);
}
</style>