<template>
  <div>
    <van-nav-bar
      title="DEMO"
    />
    <van-form>
      <van-field v-model="initData.clientCode" label="店铺编号" placeholder="店铺编号" />
      <van-field v-model="initData.chainName" disabled label="支付网络" placeholder="支付网络" />
      <van-field v-model="initData.coinUnit" disabled label="币种名称" placeholder="币种名称" />
      <van-field v-model="initData.memberFlag" label="会员唯一标记" placeholder="会员唯一标记" />
      <van-field v-model="initData.clientNo" label="订单编号" disabled placeholder="订单编号" />
      <van-field v-model="initData.requestTimestamp" label="时间戳" disabled placeholder="时间戳" />
      <van-field v-model="privateKey" :disabled="isGenerate" label="店铺密钥" placeholder="店铺密钥" />
      <van-field v-model="initData.callbackurl" label="回传地址" :disabled="isGenerate" placeholder="回传地址" />
      <van-field v-model="initData.hrefbackurl" label="跳转地址" :disabled="isGenerate" placeholder="跳转地址" />
      <van-field v-model="initData.signature" label="加密签名" disabled placeholder="加密签名" />
      <van-field v-model="initData.requestAmount" label="请求数量(INR)" :disabled="isGenerate" placeholder="请求数量(INR)" />
      <van-field name="radio" label="结果类型">
        <template #input>
          <van-radio-group v-model="initData.resultType">
            <van-radio name="0">仅查看接口参数</van-radio>
            <van-radio name="1">跳转支付页面</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div style="margin: 16px;display: flex;justify-content: center;">
        
        <van-button v-if="!isGenerate" block round type="info" @click="generate">生成</van-button>
        <van-button v-if="isGenerate" round plain hairline type="info" @click="reGenerate">重新生成</van-button>
        <van-button v-if="isGenerate" round type="primary" @click="submitData" style="margin-left: 10px;">提交</van-button>
      </div>
    </van-form>
    <van-divider />
    <van-cell-group title="字段释义">
      <van-cell value="店铺编号">
        <template #title>
          <van-tag round type="primary">clientCode</van-tag>
        </template>
      </van-cell>
      <van-cell value="支付网络">
        <template #title>
          <van-tag round type="primary">chainName</van-tag>
        </template>
      </van-cell>
      <van-cell value="币种名称">
        <template #title>
          <van-tag round type="primary">coinUnit</van-tag>
        </template>
      </van-cell>
      <van-cell value="会员唯一标记">
        <template #title>
          <van-tag round type="primary">memberFlag</van-tag>
        </template>
      </van-cell>
      <van-cell value="订单编号">
        <template #title>
          <van-tag round type="primary">clientNo</van-tag>
        </template>
      </van-cell>
      <van-cell value="发起请求的当前时间戳">
        <template #title>
          <van-tag round type="primary">requestTimestamp</van-tag>
        </template>
      </van-cell>
      <van-cell value="商户密钥">
        <template #title>
          <van-tag round type="primary">privateKey</van-tag>
        </template>
      </van-cell>
      <van-cell value="请求数量">
        <template #title>
          <van-tag round type="primary">requestAmount</van-tag>
        </template>
      </van-cell>
      <van-cell value="请求签名">
        <template #title>
          <van-tag round type="primary">md5(clientCode&chainName&coinUnit&clientNo&requestTimestamp+privateKey)</van-tag>
        </template>
      </van-cell>
    </van-cell-group>

    <van-cell-group title="调试建议">
      <van-cell>示例代码中的默认店铺编号、密钥仅为对接用测试，可替换为自己的店铺编号-密钥对。</van-cell>
      <van-cell>结果类型选择『仅查看接口参数』，可看到具体的参数提示。选择『跳转支付页面』则跳转支付页。</van-cell>
      <van-cell>点击示例中的『生成』按钮填充参数后『提交』。调试阶段可以用示例中的默认值，调通后请换成自己的。</van-cell>
      <van-cell>订单有效时长为1小时，超时将不会确认。</van-cell>
      <van-cell>回调会有多次（最多5次），请做好重复请求处理。调用方法应返回 "ok"字样，以便系统完成最后确认步骤。</van-cell>
    </van-cell-group>

    <!-- <van-cell-group title="代码片段">
      <van-cell>
        Map params =new HashMap&lt;>();

params.put("clientCode",oneTask.getClientCode());
params.put("clientNo",oneTask.getClientNo());
params.put("orderNo",oneTask.getOrderNo()); //系统订单号
params.put("chainName",oneTask.getChainName().name());
params.put("coinUnit",oneTask.getCoinUnit().name());
params.put("payAmount",oneTask.getPayAmount().toString());
//PAID为transfer成功，FINISH为回调成功且回调正确返回"ok"字样
params.put("status",oneTask.getStatus());
params.put("txid",oneTask.getTxid());

List strings = Arrays.asList(oneTask.getClientCode(), oneTask.getClientNo(), oneTask.getOrderNo(),
oneTask.getPayAmount().toString(), oneTask.getStatus(), oneTask.getTxid());

String signStr = StringUtils.join(params, "&").concat(partnerCode);
//签名字符串示例：signStr=clientCode&clientNo&orderNo&payAmount&status&txid+privateKey
String sign = DigestUtils.md5Hex(signStr);
params.put("sign",sign);
      </van-cell>
    </van-cell-group> -->

    <van-popup v-model="showPanel">
      <van-field label="订单编号" horizontal v-model="showData.orderNo" disabled />
      <van-field label="网络" horizontal disabled v-model="showData.chainName" />
      <van-field label="币种" horizontal v-model="showData.coinUnit" disabled />
      <van-field label="支付链接" horizontal v-model="showData.payUrl" disabled />
      <van-field label="接收地址" horizontal v-model="showData.receiveAddr" disabled />
      <van-field label="接收数量" horizontal v-model="showData.requestAmount" disabled />
      <van-field label="请求签名" horizontal v-model="showData.sign" disabled />
    </van-popup>
    <!-- 
        <div class="columns">
          <div class="column">
            <b-taglist attached>
              <b-tag type="is-dark">商户识别号</b-tag>
              <b-tag type="is-info">
                clientCode
              </b-tag>
            </b-taglist>
          </div>
          <div class="column">
            <b-taglist attached>
              <b-tag type="is-dark">网络</b-tag>
              <b-tag type="is-info">
                chainName
              </b-tag>
            </b-taglist>
          </div>
          <div class="column">
            <b-taglist attached>
              <b-tag type="is-dark">币种</b-tag>
              <b-tag type="is-info">
                coinUnit
              </b-tag>
            </b-taglist>
          </div>
          <div class="column">
            <b-taglist attached>
              <b-tag type="is-dark">商户会员标识</b-tag>
              <b-tag type="is-info">
                memberFlag
              </b-tag>
            </b-taglist>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-taglist attached>
              <b-tag type="is-dark">客户订单号</b-tag>
              <b-tag type="is-info">
                clientNo
              </b-tag>
            </b-taglist>
          </div>
          <div class="column">
            <b-taglist attached>
              <b-tag type="is-dark">时间戳</b-tag>
              <b-tag type="is-info">
                requestTimestamp
              </b-tag>
            </b-taglist>
          </div>
          <div class="column">
            <b-taglist attached>
              <b-tag type="is-dark">商户密钥</b-tag>
              <b-tag type="is-info">
                privateKey
              </b-tag>
            </b-taglist>
          </div>
          <div class="column">
            <b-taglist attached>
              <b-tag type="is-dark">数量</b-tag>
              <b-tag type="is-info">
                requestAmount
              </b-tag>
            </b-taglist>
          </div>
        </div>
        <b-field>
          <b-taglist attached>
            <b-tag type="is-dark">加密签名</b-tag>
            <b-tag type="is-success">
              md5(clientCode&chainName&coinUnit&clientNo&requestTimestamp+privateKey)
            </b-tag>
          </b-taglist>
        </b-field>
      </section>
      <div class="box mt-4" v-if="showPanel">
        <section>
          <b-field label="订单编号" horizontal>
              <b-input expanded v-model="showData.orderNo" disabled></b-input>
          </b-field>
          <b-field label="网络" horizontal>
              <b-input disabled v-model="showData.chainName"></b-input>
          </b-field>
          <b-field label="币种" horizontal>
              <b-input v-model="showData.coinUnit" disabled></b-input>
          </b-field>
          <b-field label="支付链接" horizontal>
              <b-input v-model="showData.payUrl" disabled></b-input>
          </b-field>
          <b-field label="接收地址" horizontal>
              <b-input v-model="showData.receiveAddr" disabled></b-input>
          </b-field>
          <b-field label="接收数量" horizontal>
              <b-input v-model="showData.requestAmount" disabled></b-input>
          </b-field>
          <b-field label="请求签名" horizontal>
              <b-input v-model="showData.sign" disabled></b-input>
          </b-field>
        </section>
      </div>
      <div class="box mt-4">
        <section>
          <nav class="panel">
            <p class="panel-heading">
              调试建议
            </p>
            <a class="panel-block is-active">
              · 示例代码中的默认店铺编号、密钥仅为对接用测试，可替换为自己的店铺编号-密钥对。
            </a>
            <a class="panel-block">
              · 结果类型选择『仅查看接口参数』，可看到具体的参数提示。选择『跳转支付页面』则跳转支付页。
            </a>
            <a class="panel-block">
              · 点击示例中的『生成』按钮填充参数后『提交』。调试阶段可以用示例中的默认值，调通后请换成自己的。
            </a>
            <a class="panel-block">
              · 订单有效时长为1小时，超时将不会确认。
            </a>
            <a class="panel-block">
              · 回调会有多次（最多5次），请做好重复请求处理。调用方法应返回 "ok"字样，以便系统完成最后确认步骤。
            </a>

          </nav>
          <div class="subtitle">代码片段</div>
          <div class="content">
            <pre>
  Map params =new HashMap&lt;&gt;();

  params.put("clientCode",oneTask.getClientCode());
  params.put("clientNo",oneTask.getClientNo());
  params.put("orderNo",oneTask.getOrderNo()); //系统订单号
  params.put("chainName",oneTask.getChainName().name());
  params.put("coinUnit",oneTask.getCoinUnit().name());
  params.put("payAmount",oneTask.getPayAmount().toString());
  //PAID为transfer成功，FINISH为回调成功且回调正确返回"ok"字样
  params.put("status",oneTask.getStatus());
  params.put("txid",oneTask.getTxid());

  List strings = Arrays.asList(oneTask.getClientCode(), oneTask.getClientNo(), oneTask.getOrderNo(),
  oneTask.getPayAmount().toString(), oneTask.getStatus(), oneTask.getTxid());

  String signStr = StringUtils.join(params, "&").concat(partnerCode);
  //签名字符串示例：signStr=clientCode&clientNo&orderNo&payAmount&status&txid+privateKey
  String sign = DigestUtils.md5Hex(signStr);
  params.put("sign",sign);
            </pre>
          </div>
        </section>
      </div>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div> -->
  </div>
</template>
<script>
import md5 from 'js-md5';
// import 'buefy/dist/buefy.css'
export default {
  data() {
    return {
      privateKey: 'ec1667e9bdc80db6f8a427f7c81c3a69',
      isGenerate: false,
      showPanel: false,
      isLoading: false,
      initData: {
        clientCode: 'S001878rm3Nu',
        requestTimestamp: '',
        chainName: 'BANK',
        coinUnit: 'INR',
        memberFlag: 'testUser001',
        callbackurl: 'https://payment.manoys.com/api/coin/pay/callback',
        hrefbackurl: 'https://payment.manoys.com/api/coin/pay/callback',
        clientNo: '',
        signature: '',
        requestAmount: 100,
        resultType: '0',
      },
      formData: {},
      showData: {},
    };
  },
  mounted() {
    // this.init();
    const { clientCode } = this.$route.query;
    if (clientCode) {
      this.initData.clientCode = clientCode;
    } else {
      // eslint-disable-next-line no-console
      console.log('use default client code');
    }
  },
  methods: {
    reGenerate() {
      this.isGenerate = false;
      this.showPanel = false;
    },
    generate() {
      const currentTime = new Date().getTime();
      this.initData.requestTimestamp = currentTime;
      this.initData.clientNo = currentTime.toString().slice(-6);
      // eslint-disable-next-line max-len
      const hashArr = [this.initData.clientCode, this.initData.chainName, this.initData.coinUnit, this.initData.clientNo, currentTime];
      this.initData.signature = md5(hashArr.join('&') + this.privateKey);
      this.formData = {
        clientCode: this.initData.clientCode,
        chainName: this.initData.chainName,
        coinUnit: this.initData.coinUnit,
        memberFlag: this.initData.memberFlag,
        requestAmount: this.initData.requestAmount,
        clientNo: this.initData.clientNo,
        requestTimestamp: this.initData.requestTimestamp,
        callbackurl: this.initData.callbackurl,
        hrefbackurl: this.initData.hrefbackurl,
        sign: this.initData.signature,
        toPayQr: 0,
      };
      this.isGenerate = true;
    },
    async submitData() {
      this.isLoading = true;
      try {
        const result = await this.$api.payRequest(this.formData);
        if (result.code === 200) {
          switch (this.initData.resultType) {
            case '0':
              this.showData = result.data;
              this.showPanel = true;
              break;
            case '1':
              this.$router.push(`/pay?orderNo=${result.data.orderNo}&refUrl=${result.data.payUrl}`);
              break;
            default:
              break;
          }
          this.isLoading = false;
        } else if (result.code === 303) {
          this.$buefy.toast.open({
            duration: 2000,
            message: result.message,
            position: 'is-top',
            type: 'is-warning',
          });
          this.isLoading = false;
        } else {
          this.$buefy.toast.open({
            duration: 3000,
            message: 'We are encountering too many transactions at the moment, please kindly try again later.',
            position: 'is-top',
            type: 'is-warning',
          });
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Network err.',
          position: 'is-top',
          type: 'is-danger',
        });
      }
      
    },
  },
};
</script>
<style scoped>
@import 'https://unprivateKeyg.com/buefy/dist/buefy.min.css';
.label {
  white-space: nowrap;
  text-align: left;
}
</style>
